body {
  font-family: system-ui;
}

main {
  display: grid;
  grid-column: 1/3;
}

textarea, form {
  margin-bottom: 1rem;
}

#article-name {
  padding: 0.25rem;
}

#status {
  height: 1rem;
  background: lightgoldenrodyellow;
  padding: 1rem;
  border-radius: 0.5rem;
}

#langlink {
  height: 2rem;
}

#wikitext {
  height: 30vh;
}

#replaced-wikitext {
  height: 30vh;
}
/*# sourceMappingURL=index.1170b0e6.css.map */
